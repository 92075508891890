export const SERVER_BACK = "https://api.legacy.climeet.events/"
export const BASE_PATH = "https://api.legacy.climeet.events/api/"
export const WEBSITE_URL = "https://climeet.events/"
export const CHECK_PASSWORD= BASE_PATH +"pass_check"
export const POST_PROFILE_IMG = BASE_PATH +"user/image"
export const LOGIN_API_PATH = BASE_PATH + "login_check"
export const POST_BACKGROUNDCOLOR = BASE_PATH +"user/backgroundColor"
export const GET_BACKGROUNDCOLOR_LOGO = BASE_PATH +"user/backgroundColorLogo"
export const REGISTER_API_PATH = BASE_PATH + "register"
export const FORGOT_API_PATH = BASE_PATH + "forgot"
export const USER_API = BASE_PATH + "users"
export const CONTACT_API = BASE_PATH + "contacts"
export const EF_COUNTRY_API = BASE_PATH + "efs"
export const DASHBOARD_API = BASE_PATH + "dashboard"
export const CALCULATOR_API = BASE_PATH + "calculator/data"
export const CALCULATOR_BASE_API = BASE_PATH + "calculators"
export const CALCULATORS_BASE_API = BASE_PATH + "calculator"
export const EMISSION_FACTOR_API = BASE_PATH + "emission-factor"
export const CREATE_EVENT = BASE_PATH + "event/create"
export const DUPLICATE_EVENT = BASE_PATH + "event/duplicate"
export const DELETE_EVENT = BASE_PATH + "event/delete"
export const GET_EVENT_DETAILS = BASE_PATH + "event"
export const GET_SERVICE_DETAILS = BASE_PATH + "service"
export const GET_EVENT_LIST = BASE_PATH + "event/list"
export const GET_USER_BALANCE = BASE_PATH + "user/balance"
export const CREATE_SCENARIO = BASE_PATH + "scenario/create"
export const DELETE_SCENARIO = BASE_PATH + "scenario/delete"
export const DUPLICATE_SCENARIO = BASE_PATH + "scenario/duplicate"
export const UPDATE_SCENARIO = BASE_PATH + "scenario/update"
export const CONFIRM_SCENARIO = BASE_PATH + "scenario/confirm"
export const OVERVIEW_SCENARIO = BASE_PATH + "scenario/overview"
export const HTML_RESULT = BASE_PATH + "scenario/gethtmlresult"
export const COLLECT_SCENARIO = BASE_PATH + "scenario/collect"
export const GET_SCENARIO = BASE_PATH + "scenario"
export const GET_INVOICES = BASE_PATH + "user/invoices"
export const GET_OFFERS = BASE_PATH + "offer/list"
export const GET_CHECKOUT_URL = BASE_PATH + "billing/checkout_session"
export const GET_FAQ = "https://climeet.events/wp-json/wp/v2/pages?slug=faq"
export const GET_MODULE = BASE_PATH + "module/list"
export const SET_MODULE = BASE_PATH + "module"
export const GET_USER_REPONSE = BASE_PATH + "userreponse/get"
export const SET_USER_REPONSE = BASE_PATH + "userreponse/set"
export const GET_USER_REPONSE_STATUS = BASE_PATH + "userreponse/status"
export const SET_VIDEO_LINK = BASE_PATH + "media"
export const DELETE_VIDEO_LINK = BASE_PATH + "media"
export const UPDATE_MEDIA = BASE_PATH + "media"
export const UPLOAD_MEDIA = BASE_PATH + "media/upload"
export const GET_MODULE_DETAIL = BASE_PATH + "module"
export const SET_QCM = BASE_PATH + "qcm/createOrUpdate"
export const ADVICE = BASE_PATH + "advice"
export const IS_VERIFIED_USER = BASE_PATH + "verify"
export const RESEND_ACTIVATION_TOKEN_EMAIL = BASE_PATH + "resendtokenemail"
export const INVITATION_MAIL = BASE_PATH + "projectManagerInviteMail"
export const PM_LIST = BASE_PATH + "projectManagerList"
export const PM_CHANGE_ACTIVE = BASE_PATH + "projectManagerActive"
export const PM_DELETE = BASE_PATH + "projectManagerDelete"
export const GET_CATALOG_LIST = BASE_PATH + "catalogList"
export const GET_MODULE_TITLE = BASE_PATH + "moduleTitle"
export const SET_MODULE_TITLE = BASE_PATH + "moduleTitle"
export const FTE_COUNTRY_API = BASE_PATH + "getData/ftecountry"
export const GET_PROVIDER_INVITATION_FOR_EVENT = BASE_PATH + "invitation/getevent"
export const SET_PROVIDER_INVITATION_FOR_EVENT = BASE_PATH + "invitation/"
export const GET_PIECE = BASE_PATH + "allpiece"
export const CREATE_PIECE = BASE_PATH + "piece"
export const UPDATE_PIECE = BASE_PATH + "piece"
export const DELETE_PIECE = BASE_PATH + "piece"
export const GET_ALLINGREDIENT = BASE_PATH + "ingredientList"
export const PIECE_LIST = BASE_PATH + "pieceTypeList"
export const PIECE_LIST_CREATE = BASE_PATH + "pieceType"
export const PIECE_LIST_UPDATE = BASE_PATH + "pieceTypeUpdate"

export const CREATE_INGREDIENT = BASE_PATH + "ingredient/"
export const UPDATE_INGREDIENT = BASE_PATH + "ingredientList/"
export const DELETE_INGREDIENT = BASE_PATH + "ingredientList"
export const SEND_CREDITS = BASE_PATH + "sendCredits"

export const ROUTE_SERVICE_PROVIDERS = BASE_PATH + "service"
export const ROUTE_SERVICE_PROVIDERS_DETAIL = BASE_PATH + "service/detail/"
export const GET_PRESTASTION_LIST = BASE_PATH + "prestationList/"
export const GET_GENERAL_PARAMS_USER = BASE_PATH + "getParametersFoUser"
export const SET_GENERAL_PARAMS = BASE_PATH + "generalParameters"
export const GET_ALLFURNITURE = BASE_PATH + "furnishingsList"
export const CREATE_FURNITURE = BASE_PATH + "furnishings/"
export const UPDATE_FURNITURE = BASE_PATH + "furnishings/"
export const DELETE_FURNITURE = BASE_PATH + "furnishingsList"
export const UPDATE_ELEARNING_ACCESSROLE = BASE_PATH + "module/role"
export const ASSIGN_PM = BASE_PATH + "event/assignPm"
export const UNASSIGN_PM = BASE_PATH + "event/unassignPm"
export const GET_MAINTENANCE = BASE_PATH + "maintenance"
export const TOGGLE_MAINTENANCE = BASE_PATH + "toggleMaintenance"
export const TRIGGER_MAINTENANCE_NOTICE = BASE_PATH + "triggerMaintenanceNotice"
export const GET_MAX_INVITATIONS = BASE_PATH +"user/getMaxInvitations"
export const NEW_APP_URL = "http://app.climeet.events"
